/*inputフォーム入力時に右側にBootstrapのアイコンを表示させない*/
$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

@import '~bootstrap/scss/bootstrap';
@import 'colors';
@import 'font';
/*========================
  HTML ELEMENTS
========================*/

body {
  color: $gray-900;
  font-family: 'Noto Sans Japanese';
}

a:hover {
  opacity: .6;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

button, input, select {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

/*========================
  BACKGROUND
========================*/
.bg-light-blue {
  background-color: $blue-10;
}

.bg-blue-600 {
  background-color: $blue-600;
}

.bg-blue {
  background-color: $blue-700;  
}

.bg-dark-blue {
  background: url("../images/bg_noise.svg"), $blue-900;
  color: $white;
  background-blend-mode: overlay, normal;
}

.bg-yellow {
  background-color: $yellow-10;
}

.bg-pindot {
  background: url("../images/pindot.svg");
}

.bg-blue-gray-50 {
  background-color: $blue-gray-50;
}

.bg-footer-gray {
  background: $blue-gray-600;
}

.bg-footer-dark-gray {
  background: $blue-gray-900;
}

/*========================
  SPACING
========================*/
/* ------- マージン（PC/SP共通） ------- */
.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-56 {
  margin-top: 56px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 { 
  margin-bottom: 32px;
}

.mb-40 { 
  margin-bottom: 40px;
}

/* ------- パディング（PC/SP共通） ------- */

.p-16 {
  padding: 16px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-32 {
  padding-top: 32px;
}

.py-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.pl-8 {
  padding-left: 8px;
}

.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

.pb-200 {
  padding-bottom: 200px;
}

/*========================
  FONT
========================*/
/* ------- フォントサイズ（PC/SP共通） ------- */
.fs-14 {
  font-size: 14px;
}

/* ------- フォントカラー（PC/SP共通） ------- */
.text-blue {
  color: $blue-700;
}

.text-light-gray {
  color: $blue-gray-500;
}

.text-gray {
  color: $blue-gray-700;
}

.link-text {
  color: $gray-900;
}

/* ------- フォントウェイト ------- */
.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

/* ------- FONT TYPE ------- */
.notation {
  font-size: 14px;
  color: $blue-gray-500;
}

/*========================
  POSITIONING
========================*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*========================
  COMPONENTS
========================*/
/* ------- ボタン ------- */
.btn-large, .btn-normal, .btn-square, .btn-small {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  border-radius: 100vh;
  border: none;
}

.btn-square {
  border-radius: 8px;
}

.btn-large:hover, .btn-normal:hover,
.btn-square:hover, .btn-small:hover {
  opacity: .6;
}

.btn-small {
  width: auto;
  padding: 7px 16px;
  font-size: 14px;
  line-height: 20px;
}

.btn-normal, .btn-square {
  padding: 12px 0;
}

.btn-large, .btn-disabled {
  padding: 16px 0;
}

.btn-blue, .btn-blue:hover {
  color: $white;
  border: 1px solid $blue-700;
  background-color: $blue-700;
}

.btn-white, .btn-white:hover {
  font-weight: 500;
  color: $gray-900;
  border: 1px solid $blue-gray-100;
  background-color: $white;
}

.btn-light-gray, .btn-light-gray:hover {
  font-weight: 400;
  color: $blue-gray-800;
  border: 1px solid $blue-gray-100;
  background-color: $white;
}

.btn-disabled, .btn-disabled:hover {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  border-radius: 100vh;
  border: none;
  color: $white;
  background-color: $gray-500;
}

.btn-nocolor {
  color: $blue-gray-500;
  background: none;
}

.btn-nocolor:hover {
  opacity: 1;
  color: $blue-gray-500;
  background-color: $blue-gray-10;
}

.btn-txt-sm {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
}

.btn-group {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid $blue-gray-100;
  border-radius: 8px;

  .btn-left-part {
    border-radius: 8px 0 0 8px;
    height: 100%;
  }

  .btn-right-part {
    border-radius: 0 8px 8px 0;
    height: 100%;
  }

  .active{
    background: $blue-gray-700;
    border: 1px solid $blue-gray-700;
    color: $white;
  }
}

/* ------- カード（共通） ------- */
.card-normal, .card-long, .card-sm, .card-article {
  display: block;
}

/* ------- カード（プロジェクト） ------- */
.card-project {
  .apply-time-box {
    border: 1px solid $blue-gray-50;
    border-radius: 8px;
    background-color: $blue-gray-10;
    margin-bottom: 18px;

    > img {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .new {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: $white;
    background: $red;
    border-radius: 4px;
  }

  h3.project-card-ttl {
    font-weight: 700;
    color: $blue-700;

    > a {
      color: $blue-700;
      text-decoration: none;
    }
  }
  
  .project-card-occupation {
    font-weight: 500;
  }
  
  ul.project-card-condition-box {
    > li {
      display: flex;
      align-items: center;
    }

    img.project-card-condition-icon {
      display: inline-block;
    }

    .project-card-condition-txt {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
    }
    
    .income-num {
      font-weight: 700;
      color: $red;
    }
    
    .income-unit {
      font-weight: 400;
      color: $gray-900;
    }
  }
  
  .project-card-skill-ttl {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $gray-700;
  }
  
  .project-card-skill {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  ul.project-card-tag-list {
    margin-bottom: 16px;

    > li {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 6px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid $blue-gray-100;
      border-radius: 4px;
      background-color: $blue-gray-10;
    }

    > li:last-child {
      margin-right: 0;
    }
  }

  ul.project-card-tag-list-apply {
    font-size: 0;

    > li {
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 6px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid $blue-gray-100;
      border-radius: 4px;
      background-color: $blue-gray-10;
    }

    > li:last-child {
      margin-right: 0;
    }
  }

  .num-of-days {
    margin: 0;
    color: $gray-500;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
  }

  .word-break {
    word-break: break-word;
  }
}

/* ------- CSS LINES ------- */
.line-blue-h-50x4 {
  display: inline-block;
  width: 50px;
  border-bottom: 4px $blue-700 solid;
}

.line_h_yellow_100x2 {
  display: inline-block;
  width: 100px;
  border-bottom: 2px $yellow-800 solid;
}

/* ------- FLOAT BAR ------- */
.float-bar {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  padding: 16px;
  text-align: center;
  background: rgba(74, 99, 118, 0.3);
  backdrop-filter: blur(2px);
}

/* ------- フォーム ------- */
.form-element {
  margin-bottom: 20px;
}

.form-element-flex {
  display: flex;
  gap: 15px;

  .form-element {
    flex-grow: 1;
  }
}

.form-label {
  margin-bottom: 8px;
  text-align: left;
}

.form-label-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $blue-gray-700;
}

.form-notation {
  font-size: 14px;
  color: $blue-gray-500;
}

.form-notation-link, .form-notation-link:hover {
  font-size: 14px;
  color: $blue-gray-500;
}

.form-control {
  padding: 12px 16px;
  font-size: 16px;
  line-height: 16px;
  border-radius: 12px;
  border: 1px solid $blue-gray-100;
}

.form-control::placeholder {
  color: $blue-gray-200;
}

.form-select {
  display: inline-block;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid $blue-gray-100;
  background: $white;
}

.form-error-message {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $red;
}

/* ------- FORM(チェックボックス) ------- */
.form-check {
  margin: 0;
  padding: 0;
}

.form-check-label {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  input.form-check-input[type=checkbox] {
    margin: 0;
    width: 0;
    opacity: 0;
  }

  .form-check-indicator {
    position: relative;
    display: block;
    width: 26px;
    height: 26px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid $blue-gray-100;
    background-color: $white;
  }

  input.form-check-input:checked + .form-check-indicator {
    border: none;
    background-color: $blue-700;
  }

  input.form-check-input:checked + .form-check-indicator::before {
    content: url('../images/check.svg');
    position: absolute;
    display: block;
    top: 2px;
    left: 4px;
  }

  .form-check-txt {
    font-size: 14px;
    line-height: 20px;
  }
}

.form-check-label:hover {
  cursor: pointer;

  .form-check-indicator {
    border: 1px solid $blue-700;
  }
}

.form-check-half {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .form-check-label {
    width: 50%;
  }
}

/* ------- FORM ボタン ------- */
.form-btn-wrap {
  margin: 32px 0px;
}

/* ------- 検索フィールド ------- */
.search-field-wrap {
  display: flex;

  input.search-field {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 23px;
    border-top-left-radius : 16px;
    -webkit-border-top-left-radius: 16px;
    -moz-border-radius-topleft: 16px;
    border-bottom-left-radius : 16px;
    -webkit-border-bottom-left-radius: 16px;
    -moz-border-radius-bottomleft: 16px;
    border: none;
  }

  .search-field-btn {
    padding: 12px;
    border-top-right-radius : 16px;
    -webkit-border-top-right-radius: 16px;
    -moz-border-radius-topright: 16px;
    border-bottom-right-radius : 16px;
    -webkit-border-bottom-right-radius: 16px;
    -moz-border-radius-bottomright: 16px;
    border: none;
    background-color: $blue-700;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

/* ------- HEADER ------- */
header {
  position: -webkit-fixed;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0px;
  background-color: $white;
  z-index: 990;
}

h1.header-subtitle {
  color: $blue-gray-500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;
}

.header-logo {
  display: inline-block;
}

.header-logo img {
  width: 100%;
}

.header-right {
  display: flex;
  justify-content: end;
  height: 60px;
}

.header-right-item {
  display: block;
  padding: 10px 4px;
  height: 60px;
  text-align: center;
  text-decoration: none;
  color: $gray-900;
}

.header-right-item img {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto 2px;
}

.header-right-item span {
  display: block;
  margin: 0 auto;
  line-height: 14px;
  font-size: 10px;
  font-weight: 500;
}

#headerMenu {
  display: none;
}

/* ------- MESSAGE BAR ------- */
.message-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100vw;
  height: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.message-bar-notice {
  background: $green;
}

.message-bar-alert {
  background: $red;
}

.message-bar-text {
  font-size: 16px;
  font-weight: 500;
  color: $white;
}

/* ------- MODAL ------- */
.modal-1000 {
  max-width: 1000px;
  min-width: 1000px;
}

.modal-content {
  height: 80%;
  padding: 32px;
  border-radius: 16px;
  border: 1px solid $blue-gray-100;
}

h3.modal-title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.sec-modal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-bottom: 32px;
  gap: 25px;
  overflow: hidden;
}

.sec-modal-side {
  width: 284px;
  height: 100%;
}

.sec-modal-main {
  width: 100%;
}

.modal-side-list {
  height: 100%;
  border: 1px solid $blue-gray-100;
  border-radius: 8px;
  
  > li {
    border-bottom: 1px solid $blue-gray-100;

    .modal-side-list-link {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      font-size: 16px;
      line-height: 23px;
      color: $gray-900;
      text-decoration: none;
    }

    .modal-side-arrow {
      display: inline-block;
      margin-left: auto;
      width: 16px;
      height: 16px;
    }
  }
}

.modal-main-list {
  height: 100%;
  border-radius: 8px;
  border: 1px solid $blue-gray-100;
  overflow-y: scroll;

  > li {
    border-bottom: 1px solid $blue-gray-100;

    .form-check-label {
      margin: 0;
      padding: 16px 24px;
    }
  }
}

.modal-main-flex-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;  
  height: 100%;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid $blue-gray-100;
  overflow-y: scroll;

  > li {
    display: list-item;
    width: 25%;

    .form-check-label {
      margin-bottom: 20px;
    }
  }
}

.modal-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  button {
    width: auto;
    padding-left: 42px;
    padding-right: 42px;
  }
}

/* ------- PAGENATION ------- */
.pagination {
  margin-bottom: 40px;
  justify-content: center;
}

/*========================
  HOME
========================*/
/* ------- 共通 ------- */
h2.home-h2-left {
  font-weight: 700;
  letter-spacing: 0.05em;
}

h2.home-h2-center {
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.05em;
}

h2.home-h2-blue, h2.home-h2-yellow {
  position: relative;
}

h2.home-h2-blue::after, h2.home-h2-yellow::after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: $blue-700;
}

h2.home-h2-left.home-h2-blue::after,
h2.home-h2-left.home-h2-yellow::after {
  left: 0;
}

h2.home-h2-center.home-h2-blue::after,
h2.home-h2-center.home-h2-yellow::after {
  left: 0;
  right: 0;
  margin: auto;
}

h2.home-h2-blue::after {
  background-color: $blue-700;
}

h2.home-h2-yellow::after {
  background-color: $yellow-800;
}

.home-bottom-link {
  margin: 0;
  padding: 0 16px;
  text-align: right;

  > a {
    color: $gray-900;
    text-decoration: none;

    > span {
      display: inline-block;
      margin-right: 8px;
      text-decoration: underline;
    }

    > img {
      width: 24px;
      height: 24px;
    }
  }
}

/* ------- サポート ------- */
.home-support-bg {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.home-support-card {
  margin: 0 auto;
  padding: 72px;
  background-color: $white;
  border-radius: 12px;
  border: 2px solid $blue-gray-100;
}

.home-support-card-head {
  margin-bottom: 40px;

  .h3-home-support {
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $blue-700;
  }
}

.home-support-card-txt {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.11em;
  color: $gray-900;
}

/* ------- フロー ------- */
ul.home-flow-list {
  > li {
    .home-flow-list-item-head {
      img.flow-num {
        display: block;
        width: auto;
      }

      img.flow-icon {
        display: block;
      }

      .home-flow-list-item-ttl {
        display: block;
        font-weight: 700;
      }
    }

    p.home-flow-list-item-txt {
      margin: 0;
    }
  }
}

/* ------- Q&A ------- */
ul.home-qa-list {
  margin: 0 auto;

  > li {
    > a {
      color: $gray-900;
      text-decoration: none;

      .home-q {
        display: flex;
        align-items: center;
        justify-content: flex-start;
  
        img.q-icon {
          display: block;
          height: auto;
        }
  
        h3.q-ttl {
          margin: 0;
          font-weight: 500;
        }
  
        img.q-arrow-down, img.q-arrow-up {
          margin-left: auto;
        }

        img.q-arrow-down {
          display: block;
        }
  
        img.q-arrow-up {
          display: none;
        }
      }
  
      .home-a {
        display: none;
        font-weight: 400;
      }
    }

    > a.open {
      img.q-arrow-down {
        display: none;
      }

      img.q-arrow-up {
        display: block;
      }

      .home-a {
        display: block;
        animation: appear 1s ease;
      }

      @keyframes appear {
        0%{
          opacity: 0;
        }
        100%{
          opacity: 1;
        }
      }
    }

    > a:hover {
      opacity: 1;
    }
  }

  > li:last-child {
    margin-bottom: 0;
  }
}

/* ------- お役立ち情報 ------- */
ul.home-article-list {
  > li {
    > a {
      text-decoration: none;

      .article-main-wrap {
        display: flex;
        gap: 24px;

        .thumbnail {
          align-self: center;
          display: block;
          border-radius: 2px;
        }
  
        .title {
          flex: 1;
          font-weight: 500;
          color: $blue-gray-900;
        }
      }

      .article-dtl-wrap {
        display: flex;
        justify-content: space-between;

        .category {
          align-self: center;
          padding: 2px 16px;
          font-weight: 500;
          color: $white;
          background-color: $blue-700;
        }

        .created-at {
          align-self: center;
          font-weight: 400;
          color: $blue-gray-500;
        }
      }
    }
  }
}

/* ------- SEARCH ------- */
.search-result-summary {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.search-result-summary .sr-num {
  color: $blue-700;
}