@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/NotoSansCJKjp-Thin.eot'); /* IE9 Compat Modes */
  src: url('../fonts/NotoSansCJKjp-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/NotoSansCJKjp-Thin.woff') format('woff'), /* Modern Browsers */
  url('../fonts/NotoSansCJKjp-Thin.ttf')  format('truetype'); /* Safari, Android, iOS */
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/NotoSansCJKjp-Light.woff2') format('woff2'),
  url('../fonts/NotoSansCJKjp-Light.woff') format('woff'),
  url('../fonts/NotoSansCJKjp-Light.ttf')  format('truetype'),
  url('../fonts/NotoSansCJKjp-Light.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/NotoSansCJKjp-DemiLight.woff2') format('woff2'),
  url('../fonts/NotoSansCJKjp-DemiLight.woff') format('woff'),
  url('../fonts/NotoSansCJKjp-DemiLight.ttf')  format('truetype'),
  url('../fonts/NotoSansCJKjp-DemiLight.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NotoSansCJKjp-Regular.woff2') format('woff2'),
  url('../fonts/NotoSansCJKjp-Regular.woff') format('woff'),
  url('../fonts/NotoSansCJKjp-Regular.ttf')  format('truetype'),
  url('../fonts/NotoSansCJKjp-Regular.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/NotoSansCJKjp-Medium.woff2') format('woff2'),
  url('../fonts/NotoSansCJKjp-Medium.woff') format('woff'),
  url('../fonts/NotoSansCJKjp-Medium.ttf')  format('truetype'),
  url('../fonts/NotoSansCJKjp-Medium.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/NotoSansCJKjp-Bold.woff2') format('woff2'),
  url('../fonts/NotoSansCJKjp-Bold.woff') format('woff'),
  url('../fonts/NotoSansCJKjp-Bold.ttf')  format('truetype'),
  url('../fonts/NotoSansCJKjp-Bold.eot') format('embedded-opentype');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/NotoSansCJKjp-Black.woff2') format('woff2'),
  url('../fonts/NotoSansCJKjp-Black.woff') format('woff'),
  url('../fonts/NotoSansCJKjp-Black.ttf')  format('truetype'),
  url('../fonts/NotoSansCJKjp-Black.eot') format('embedded-opentype');
}
